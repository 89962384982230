















import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class AsyncPopup extends Vue {

  @Prop({ type: String }) title!: string

  isOpen = false

  resolve: any = null

  reject: any = null

  open(): Promise<void> {
    this.isOpen = true
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  onRejectClick(): void {
    this.reject()
    this.isOpen = false
  }

  onResolveClick(): void {
    this.resolve()
    this.isOpen = false
  }

}
