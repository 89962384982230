import { CalendarPosts, CreatedPost } from '@/includes/types/Post.types'

import { PostState, PostType } from 'piramis-base-components/src/shared/modules/posting/types'
import { SplitTags } from 'piramis-base-components/src/shared/modules/posting/PostPreview/includes/helpers'

import { EventInput } from '@fullcalendar/vue'
import { groupBy, isEmpty } from 'lodash'
import moment from 'moment'

export function postColor(post:CreatedPost, postRunTime: string): Array<string> {
    if (isEmpty(post)) {
        return [ 'target-marker' ]
    } else {
        if (post.color !== 'null' && post.color && post.state !== PostState.Error) {
            return [ post.color ]
        } else {
            if (post.state === PostState.Complete) {
                return [ 'complete' ]
            }

            if (post.state === PostState.Active && post.run_time && post.run_time.length === 1 && !post.schedule?.period) {
                return [ 'regular' ]
            }

            if (post.state === PostState.Error) {
                return [ 'error' ]
            }

            if ([ PostState.PublishedDeleted, PostState.Deleted ].includes(post.state)) {
                return [ 'regular' ]
            }

            if (post.run_time) {
                if (!post.has_posts_outside_interval && postRunTime === post.run_time.at(-1)) {
                    return [ 'repeat-last' ]
                }

                return [ 'repeat' ]
            }

            return [ 'regular' ]
        }
    }
}

export function isEditButtonVisible(post: CreatedPost): boolean {
    if (post.state === PostState.Error) return false
    if (post.state === PostState.Complete) return post.post.message.type === PostType.Post
    return true
}

function setDatesPosts(dates: Array<string>, post: CreatedPost) {
    return dates.reduce((acc: Array<EventInput>, value) => {
        acc.push(postObjectFabric(value, post))

        return acc
    }, [])
}

export function multiplyPosts(post: CreatedPost) {
    let dates = post.run_time
    const shortedEvents: Array<EventInput> = []
    const fullEvents: Array<EventInput> = []

    if (Array.isArray(dates)) {
        const grouped = groupBy(dates, (date) => date.split(' ')[0])

        const shortedDates = Object.values(grouped).reduce((acc: Array<string>, dateTime) => {
            acc.push(...dateTime.slice(0, 1))

            return acc
        }, [])

        shortedEvents.push(...setDatesPosts(shortedDates, post))
        fullEvents.push(...setDatesPosts(dates, post))
    } else {
        shortedEvents.push(postObjectFabric(dates, post))
        fullEvents.push(postObjectFabric(dates, post))
    }

    return { shortedEvents, fullEvents }
}

export function postObjectFabric(postRunTime: string, post: CreatedPost): EventInput {
    return {
        extendedProps: {
            ...post,
            postTime: postRunTime
        },
        id: post.key,
        start: postRunTime,
        end: moment(postRunTime).add(1, 'second').toDate(),
        time: postRunTime,
        allDay: false,
        classNames: postColor(post, postRunTime),
    }
}

export function getPostText(message: CalendarPosts['post']['message'], limit?: number) {
    const dots = limit !== undefined ? '...' : ''

    if (message.type === PostType.Post && message.variants.length) {
        return SplitTags(message.variants[0].text).slice(0, limit).trim() + dots
    } else if (message.type === PostType.Poll || message.type === PostType.Quiz) {
        return message.text.slice(0, limit).trim() + dots
    } else {
        return ''
    }
}