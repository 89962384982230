import { createPlugin } from '@fullcalendar/vue'

const CustomViewConfig = {

    classNames: [ 'custom-view' ],

    content: () => {
        const html = '<my-element />'
        return { html: html }
    }

}

export default createPlugin({
    views: {
        custom: CustomViewConfig
    }
})
